import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function CardDemo() {
  const amount = 27000;
  const [wsmPrice, updateWSMPrice] = useState(0);

  const fetchCoinMarketCapData = () => {
    fetch(
      'https://pro-api.coinmarketcap.com/v2/cryptocurrency/quotes/latest?slug=wall-street-memes',
      {
        headers: {
          'X-CMC_PRO_API_KEY': '8c1130f3-e7ca-418b-9b58-57c93bf57944',
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log('resp: ', response.data['28179'].quote['USD'].price);
        updateWSMPrice(response.data['28179'].quote['USD'].price);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchCoinMarketCapData();
  }, []);
  return (
    <div
      style={{
        // margin: '25%',
        marginBottom: '50%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          width: 300,
          borderRadius: 10,
        }}
        variant="outlined"
      >
        <CardContent>
          <Typography variant="h3" gutterBottom>
            WSM Balance
          </Typography>
          <Typography variant="h4" component="div">
            ${Math.round((wsmPrice * amount + Number.EPSILON) * 100) / 100}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            Your Total Assets
          </Typography>
          <Typography variant="body1">
            WSM Price
            <br />${parseFloat(wsmPrice.toFixed(4))}
          </Typography>
        </CardContent>
        {/* <CardActions>
          <Button size="small">Card Button</Button>
        </CardActions> */}
      </Card>
    </div>
  );
}
