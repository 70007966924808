import './App.css';
import Connect from './Connect';
import CardDemo from './Card';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* <h2>Your WSM Balance</h2> */}
        {/* <Connect /> */}
        <CardDemo />
      </header>
    </div>
  );
}

export default App;
